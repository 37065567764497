<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-primary">
              {{ username }}
              <feather-icon
                icon="ChevronDownIcon"
                style="color: #9100ff !important;"
                size="20"
              />
            </p>
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logOut"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, // BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
// import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    // BAvatar,

    // Navbar Components
    DarkToggler,
    // SearchBar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      username: '',
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    logOut() {
      this.$store.commit('user-auth/auth_denied')
      this.$router.push({ path: '/login' })
    },
    getUserInfo() {
      const user = this.$store.getters['user-auth/getUser']
      this.username = `${user.name || 'UpSkill'} ${user.last_name || ''}`
    },
  },
}
</script>
