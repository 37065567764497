export default [
  {
    title: 'Kanban',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Chat',
    route: 'chat',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Settings',
    icon: 'UserIcon',
    children: [
      {
        title: 'Users',
        route: 'config-users-list',
      },
    ],
  },
]
